.rbc-toolbar {
    color: white;
}

.rbc-btn-group button {
    color: white;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        background-color: #FD174F;
        color: white;
    }
}

.rbc-btn-group button.rbc-active {
    background-color: #FD174F;
    color: white;

    &:hover,
    &:focus,
    &:active  {
        background-color: #FD174F;
        color: white;
        cursor: default;
    }
}
.rbc-today {
    background-color: transparent;
}
.rbc-header.rbc-today {
    border: 1px solid #FD174F;
    color: #FD174F;
}
.rbc-day-bg.rbc-today {
    background-color: #888888;
}
.rbc-allday-cell {  
    .rbc-row-bg {
        .rbc-day-bg.rbc-today {
            background-color: transparent;
        }
    }
}
.rbc-off-range-bg {
    background-color: black;
}

.rbc-event {
    background-color: #FD174F;
}

